import cities from './services/cities.json';
import provinces from './services/provinces.json';

export const API_URL = 'https://livemuseum.it/api';
export const INSTITUTE_TYPES = [
    'Archivio',
    'Museo',
    'Monumento',
    'Biblioteca',
    'Architettura civile',
    'Parco archeologico',
    'Villa o palazzo di interesse storico o artistico',
    'Parco o giardino di interesse storico o artistico',
    'Architettura fortificata',
    'Chiesa o edificio di culto',
    'Monumento di archeologia industriale',
    'Area archeologica',
    'Altro',
];

export const SERVICES_COLORS = {
    Biblioteca: 'magenta',
    Ristorazione: 'red',
    'Visite guidate': 'volcano',
    'Sala convegni': 'orange',
    Archivio: 'gold',
    Didascalie: 'lime',
    Guardaroba: 'green',
    Audioguide: 'cyan',
    'Spazi espositivi': 'blue',
    'Connessione WI-FI': 'geekblue',
    'Laboratorio di restauro': 'purple',
    'Assistenza disabili': 'blue',
    'Sala per la didattica': 'red',
    'Postazioni multimediali': 'volcano',
    Bookshop: 'orange',
    Prestito: 'gold',
    'Guide e cataloghi': 'lime',
    Caffetteria: 'green',
    'Percorsi segnalati': 'cyan',
    Fototeca: 'blue',
    Altro: 'lime',
};
export const REGIONS = [
    'Abruzzo',
    'Basilicata',
    'Calabria',
    'Campania',
    'Emilia-Romagna',
    'Friuli-Venezia Giulia',
    'Lazio',
    'Liguria',
    'Lombardia',
    'Marche',
    'Molise',
    'Piemonte',
    'Puglia',
    'Sardegna',
    'Sicilia',
    'Toscana',
    'Trentino-Alto Adige',
    'Umbria',
    "Valle d'Aosta/Vallée d'Aoste",
    'Veneto',
];

export const PROVINCES = provinces;
export const CITIES = cities;

export default {};
