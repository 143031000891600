import { message } from 'antd';
import mock from './mock.json';
import mockSingle from './mock-single.json';
import { API_URL, SERVICES_COLORS } from '../constants';

export interface Coordinate {
    lat: number;
    lng: number;
}

export interface CulturalSite extends Coordinate {
    id: string;
    title: string;
    description?: string;
    fulladdress: string | null;
    address: string | null;
    province: string;
    city: string;
    region: string;
    zipcode: string | null;
    type: string;
    image_url: string | null;
    services: (keyof typeof SERVICES_COLORS)[] | null;
    emails?: string[];
    phones?: string[];
    websites?: string[];
    openings: { label: string; description: string }[];
    relevancy: number;
}
const development = process.env.NODE_ENV === 'development';

function calculateCulturalsiteRelevancy(cs: CulturalSite) {
    return (
        JSON.stringify(cs).length +
        (Object.keys(cs) as (keyof CulturalSite)[]).reduce(
            (res, key) =>
                res +
                (cs[key] !== undefined && cs[key] !== null && cs[key] !== [] && cs[key] !== ''
                    ? 100000
                    : 0),
            0,
        )
    );
}

export async function getAllCulturalSites(): Promise<CulturalSite[]> {
    const result: CulturalSite[] = development
        ? (mock as any)
        : await (await fetch(`${API_URL}/culturalSites`, { method: 'POST' })).json();

    return result.map((cs) => ({ ...cs, relevancy: calculateCulturalsiteRelevancy(cs) }));
}

export async function getCulturalSite(id: string | number): Promise<CulturalSite> {
    return fetch(`${API_URL}/culturalSites/${id}`, { method: 'POST' })
        .then((data) => data.json())
        .catch((err) => {
            message.error('Errore server. Contattare assistenza.');
            console.error(err);
        });
}

export default {};
