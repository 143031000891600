import { getDistance } from 'geolib';
import { latLngBounds } from 'leaflet';
import { Coordinate } from './services';

type MapGroup<T extends Coordinate> = {
    center: Coordinate;
    items: T[];
};

export function extractTextFromHtml(html: string, limit: number): string | undefined | null {
    const res = new DOMParser().parseFromString(html, 'text/html').documentElement.textContent;
    if (limit) return res?.substring(0, limit);
    return res;
}

/*
function averageGeolocation<T extends Coordinate>(coords: T[]): Coordinate {
    if (coords.length === 1) {
        return coords[0];
    }

    let x = 0.0;
    let y = 0.0;
    let z = 0.0;

    for (let i = 0; i < coords.length; i++) {
        const coord = coords[i];
        const latitude = (coord.latitude * Math.PI) / 180;
        const longitude = (coord.longitude * Math.PI) / 180;

        x += Math.cos(latitude) * Math.cos(longitude);
        y += Math.cos(latitude) * Math.sin(longitude);
        z += Math.sin(latitude);
    }

    const total = coords.length;

    x /= total;
    y /= total;
    z /= total;

    const centralLongitude = Math.atan2(y, x);
    const centralSquareRoot = Math.sqrt(x * x + y * y);
    const centralLatitude = Math.atan2(z, centralSquareRoot);

    return {
        latitude: (centralLatitude * 180) / Math.PI,
        longitude: (centralLongitude * 180) / Math.PI,
    };
}
*/
export function isDefined(obj: any) {
    return obj !== undefined && obj !== null;
}

function zoomToGroupDistance(zoom: number) {
    if (zoom <= 5) return 1000000;
    if (zoom === 6) return 150000;
    if (zoom === 7) return 100000;
    if (zoom === 8) return 80000;
    return 50000;
}

export function groupCoordinatesByRadius<T extends Coordinate>(
    culturalSites: T[],
    zoom: number,
): MapGroup<T>[] {
    const groupDistance = zoomToGroupDistance(zoom);
    const groups: T[][] = [];
    for (let i = 0; i < culturalSites.length; i++) {
        if (culturalSites[i].lat && culturalSites[i].lng) {
            let found = false;
            for (let j = 0; j < groups.length && !found; j++) {
                const distance = getDistance(groups[j][0], culturalSites[i]);
                if (distance < groupDistance) {
                    groups[j].push(culturalSites[i]);
                    found = true;
                }
            }
            if (!found) {
                groups.push([culturalSites[i]]);
            }
        }
    }

    return groups.map((g) => ({ center: latLngBounds(g).getCenter(), items: g }));
}

export default {};
