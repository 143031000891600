import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { ConfigProvider } from 'antd';
import it_IT from 'antd/lib/locale/it_IT';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import App, { OnlySearchTool } from './App';
import reportWebVitals from './reportWebVitals';

const searchTool = document.getElementById('livemuseum-search-widget');
const searchPage = document.getElementById('livemuseum-search-page');

if (searchTool) {
    ReactDOM.render(<OnlySearchTool />, searchTool);
} else if (searchPage) {
    ReactDOM.render(
        <React.StrictMode>
            <ConfigProvider locale={it_IT}>
                <Router>
                    <Routes>
                        <Route path="/searchtool" element={<OnlySearchTool />} />
                        <Route path="/" element={<App />} />
                    </Routes>
                </Router>
            </ConfigProvider>
        </React.StrictMode>,
        searchPage,
    );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
